import { LoggerConfig } from 'src/app/utils/logger';

export const environment = {
  envName: 'dev',
  softwareVersion: '2753',
  API_BASE_PATH: 'https://dev-api-mit.evida.dk',
  login: {
    basePath: 'https://dev-auth-mit.evida.dk',
    configurationUrl: 'https://dev-auth-mit.evida.dk/.well-known/openid-configuration',
    redirectUrl: window.location.origin + '/login/flow',
    redirectLogoutUrl: window.location.origin + '/login',
    scope: 'openid profile offline_access mitevida',
    privateClientId: 'mitevida.privat',
    businessClientId: 'mitevida.erhverv',
  },
  queueIt: {
    enabled: false,
    customer: 'evida',
    scriptInterceptDomain: 'evida.dk',
    interceptDomains: ['dev-api-mit.evida.dk', 'dev-auth-mit.evida.dk'],
  },
  links: {
    login: window.location.origin + '/login',
    welcome: window.location.origin + '/evida',
  },
  features: {
    testingTools: true,
    enableProfile: true,
    enableWrongReceiverFlow: true,
    enableProductionDevelopment: true,
    enableCollectConsent: true,
    enableInvoice: true,
    enableMaintenance: false,
  },
  ga: {
    appName: 'Mit evida',
    GA_PROJ_ID: 'G-7Z93VBZ9PJ', // Flow ID -> 2170357647
    enabled: false,
    loggingEnabled: true,
  },
  loggingConfig: {
    enabled: true,
    errorLogs: true,
    debugLogs: true,
    remoteReporting: false,
  } as LoggerConfig,
};
